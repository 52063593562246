import { Vue } from 'vue-class-component'
import { useRoute, useRouter } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import MembersDataService from '@/services/MembersDataService'
import { Events } from '@/services/EventsDataService'
import BaseFunctions, { defaultClubManagerGroupId } from '@/components/Utility/Base'
import CommonFunctions, { defaultClubId, defaultInvitationURL } from '@/components/Utility/Common'
import SysMember from '@/types/SysMember'
import SysEvent, { SysEventRegistration } from '@/types/SysEvent'
import SysCart from '@/types/SysCart'
import AuthService from '@/services/AuthService'

type courseDataReturn = { title: string; description: string, price: number; start: string; end: string; place: string; documents: any[], clubEmail: string; clubTelephone: string; clubName: string; extraContactMail: string; extraContactTelephone: string, registrationDeadline: string; }
type dataReturnType = { courses: any; totalCourses: number; courseDetails: courseDataReturn; memberRights: number }

export default class courses extends Vue {
  private usedrouter = useRouter()
  private usedroute = useRoute()
  private memberID = 0
  private memberInfo: SysMember[] = []
  private todaysDate = new Date().toISOString().split('T')[0]
  private courseDetails: courseDataReturn = { title: '', description: '', price: 0, start: '', end: '', place: '', documents: [], clubEmail: '', clubTelephone: '', clubName: '', extraContactMail: '', extraContactTelephone: '', registrationDeadline: '' }
  private courseDetailsModal = false
  private courses: SysEvent[] = []
  private courseHeld = `&event_start_gte=${this.todaysDate}`
  private currentListSortingOrder = 'event_start:asc'
  private oldSort = ''
  private currentPage = 1
  private totalCourses = 0
  private totalNumberOfPages = 0
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  filterByTimeValue = 'Kommende kurser'
  filterByTimeOptions: string[] = ['Alle kurser', 'Kommende kurser', 'Afholdte kurser']
  private defaultURL = defaultInvitationURL
  private clubManagerId = defaultClubManagerGroupId
  private signUpModal = false
  private successSignedUpModal = false
  private signUpFree = true
  private courseInfo = {} as SysEvent
  private signedUpToEvent: SysEventRegistration[] = []
  private clubMemberOptions: { memberName: string, memberId: number, clubID: number }[] = [{ memberName: 'Vælg medlem', memberId: 0, clubID: 0 }]
  private clubMemberValue = { memberName: 'Vælg medlem', memberId: 0, clubID: 0 }
  private memberRights = 0

  readonly name : string = 'courses'
  $Message: any
  data (): dataReturnType {
    return {
      courses: this.courses,
      totalCourses: this.totalCourses,
      courseDetails: this.courseDetails,
      memberRights: this.memberRights
    }
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1

    this.retrieveCourses()
  }

  @Watch('filterByTimeValue')
  onFilterByTimeValueChange (status: string) : void {
    if (status === 'Alle kurser') {
      this.courseHeld = ''
    }

    if (status === 'Kommende kurser') {
      this.courseHeld = `&event_start_gte=${this.todaysDate}`
    }

    if (status === 'Afholdte kurser') {
      this.courseHeld = `&event_start_lt=${this.todaysDate}`
    }

    this.retrieveCourses()
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveCourses()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveCourses()
  }

  public columnSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'event_start:asc'
      this.oldSort = ''
      this.currentPage = 1
      this.filterByTimeValue = 'Kommende kurser'
      this.courseHeld = `&event_start_gte=${this.todaysDate}`
    }

    if (sortBy === 'courseName') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_titel:desc'
        this.oldSort = ''
      }
    }

    if (sortBy === 'registrationDeadline') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_sidstetilmelding:asc,event_start:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_sidstetilmelding:desc,event_start:desc'
        this.oldSort = ''
      }
    }

    if (sortBy === 'courseDate') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_start:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_start:asc'
        this.oldSort = ''
      }
    }

    this.retrieveCourses()
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    // console.log('[locConvertFromUTCtoLocalDateTime()] inputDatetimeString = ' + inputDatetimeString)
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public danishDataWClock (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 5) + ' kl. ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public signUp (EventId: number) : void {
    Events.EventsDataService.get(EventId.toString())
      .then((response) => {
        this.courseInfo = response.data
        this.signUpModal = true
      })
      .catch((err) => {
        console.error(err)
      })
    Events.EventRegistrationDataService.getAll('', null, `event_id.id=${EventId.toString()}`)
      .then((response) => {
        this.signedUpToEvent = response.data
        console.log(this.signedUpToEvent)
      })
      .catch((err) => {
        console.error(err)
      })

    if (this.memberInfo[0].user_id.usrgroup === this.clubManagerId) {
      let clubMembers: SysMember[] = []

      MembersDataService.getAll('', null, `medlem_status=true&medlem_licens_slut_gte=${this.todaysDate}`)
        .then((response) => {
          clubMembers = response.data
          this.clubMemberOptions = [{ memberName: 'Vælg medlem', memberId: 0, clubID: 0 }]

          for (const member of clubMembers) {
            this.clubMemberOptions.push({ memberName: (member.user_id.firstname + ' ' + member.user_id.lastname + ' [' + (member.medlem_licens === null ? 'Ukendt' : member.medlem_licens.toString()) + ']'), memberId: Number(member.id), clubID: Number(member.klubber_id.id) })
          }

          // console.log(this.clubMemberOptions)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  public registerMember () : any {
    // First check if the member have already been signed up to this event.
    if (this.clubMemberValue.memberId === 0) {
      return this.$Message.warning({ text: 'Vælg et medlem' })
    }

    const alreadySignedUp = this.signedUpToEvent.some((o) => o.medlem_id.id === this.clubMemberValue.memberId)
    if (alreadySignedUp) {
      return this.$Message.warning({ text: 'Medlemmet er allerede tilmeldt kurset' })
    }

    if (this.courseInfo.event_pris > 0 && this.courseInfo.produkt_id !== null) {
      if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
      }

      const cartItems: SysCart[] = JSON.parse(localStorage.getItem('cart')!)

      // Check if the registration is already present en the cart.
      for (const cartItem of cartItems) {
        if (cartItem.eventSignUp !== null && cartItem.eventSignUp.memberId === this.clubMemberValue.memberId) {
          return this.$Message.warning({ text: 'Tilmeldingen er allerede i kurven' })
        }
      }

      const extraItemData: any = {
        type: 103,
        clubId: (this.clubMemberValue.clubID === null || this.clubMemberValue.clubID < 1 ? defaultClubId : this.clubMemberValue.clubID),
        clubSaldoReduction: 0,
        memberId: Number(this.clubMemberValue.memberId),
        eventId: Number(this.courseInfo.id)
      }

      let newItem = {} as SysCart

      newItem = {
        placeInCart: Number(cartItems.length),
        productName: this.courseInfo.produkt_id.produkt_navn,
        productDescription: this.clubMemberValue.memberName + ' tilmelding til ' + this.courseInfo.event_titel,
        price: this.courseInfo.produkt_id.produkt_pris,
        dateInCart: this.todaysDate,
        expirationDate: (this.courseInfo.event_sidstetilmelding === null ? this.courseInfo.event_start : this.courseInfo.event_sidstetilmelding),
        productId: Number(this.courseInfo.produkt_id.id),
        quantity: 1,
        playerRegistration: null,
        licenseRenewal: null,
        clubLicenseRenewal: null,
        eventSignUp: { memberId: Number(this.clubMemberValue.memberId), eventId: Number(this.courseInfo.id) },
        teamRegistration: null,
        competitionFeeData: null,
        orderExtraData: extraItemData
      }

      cartItems.push(newItem)

      localStorage.setItem('cart', JSON.stringify(cartItems))
      this.$Message.success({ text: 'Tilmeldingen er blevet tilføjet kurven' })
      this.clubMemberValue = { memberName: 'Vælg medlem', memberId: 0, clubID: 0 }
    } else {
      // FIXME: This situation should be handled with separate API endpoint request, that handles this situation only.
      const createRegistration = {
        medlem_id: this.clubMemberValue.memberId,
        event_id: Number(this.courseInfo.id)
      }

      Events.EventRegistrationDataService.create(createRegistration)
        .then((response) => {
          console.log('Registration created: ' + response.statusText)
          this.$Message.success({ text: 'Tilmeldingen er blevet registreret' })
          this.clubMemberValue = { memberName: 'Vælg medlem', memberId: 0, clubID: 0 }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  public registerYourSelf () : any {
    // First check if the member have already been signed up to this event.
    const alreadySignedUp = this.signedUpToEvent.some((o) => o.medlem_id.id === this.memberInfo[0].id)

    if (alreadySignedUp) {
      return this.$Message.warning({ text: 'Du er allerede tilmeldt kurset' })
    }

    if (this.courseInfo.event_pris > 0 && this.courseInfo.produkt_id !== null) {
      if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
      }

      const cartItems: SysCart [] = JSON.parse(localStorage.getItem('cart')!)

      // Check if the registration is already present in the cart.
      for (const cartItem of cartItems) {
        if (cartItem.eventSignUp !== null && cartItem.eventSignUp.memberId === this.memberInfo[0].id) {
          return this.$Message.warning({ text: 'Din tilmelding er allerede i kurven' })
        }
      }

      const extraItemData: any = {
        type: 103,
        clubId: (this.memberInfo[0]?.klubber_id === null || this.memberInfo[0]?.klubber_id?.id === null || Number(this.memberInfo[0]?.klubber_id?.id) < 1 ? defaultClubId : this.memberInfo[0]?.klubber_id?.id),
        clubSaldoReduction: 0,
        memberId: Number(this.memberInfo[0].id),
        eventId: Number(this.courseInfo.id)
      }

      let newItem = {} as SysCart

      newItem = {
        placeInCart: Number(cartItems.length),
        productName: this.courseInfo.produkt_id.produkt_navn,
        productDescription: this.memberInfo[0].user_id.firstname + ' ' + this.memberInfo[0].user_id.lastname + ' tilmelding til ' + this.courseInfo.event_titel,
        price: this.courseInfo.produkt_id.produkt_pris,
        dateInCart: this.todaysDate,
        expirationDate: (this.courseInfo.event_sidstetilmelding === null ? this.courseInfo.event_start : this.courseInfo.event_sidstetilmelding),
        productId: Number(this.courseInfo.produkt_id.id),
        quantity: 1,
        playerRegistration: null,
        licenseRenewal: null,
        clubLicenseRenewal: null,
        eventSignUp: { memberId: Number(this.memberInfo[0].id), eventId: Number(this.courseInfo.id) },
        teamRegistration: null,
        competitionFeeData: null,
        orderExtraData: null
      }

      cartItems.push(newItem)

      localStorage.setItem('cart', JSON.stringify(cartItems))
      this.signUpModal = false
      this.successSignedUpModal = true
      this.signUpFree = false
    } else {
      // FIXME: This situation should be handled with separate API endpoint request, that handles this situation only.
      const createRegistration = {
        medlem_id: Number(this.memberInfo[0].id),
        event_id: Number(this.courseInfo.id)
      }

      Events.EventRegistrationDataService.create(createRegistration)
        .then((response) => {
          console.log('Registration created: ' + response.statusText)
          this.signUpModal = false
          this.successSignedUpModal = true
          this.signUpFree = true
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  public toCheckOut () : void {
    this.signUpModal = false
    this.successSignedUpModal = false
    this.$router.push('/kassen')
  }

  public viewCourse (id: number) : void {
    let tempCourse = {} as SysEvent

    Events.EventsDataService.get(id.toString())
      .then((response) => {
        tempCourse = response.data
        this.courseDetails = {
          title: tempCourse.event_titel,
          description: tempCourse.event_beskrivelse,
          price: tempCourse.event_pris,
          start: tempCourse.event_start,
          end: tempCourse.event_slut,
          registrationDeadline: (tempCourse.event_sidstetilmelding === null ? tempCourse.event_start : tempCourse.event_sidstetilmelding),
          place: tempCourse.event_sted,
          clubEmail: tempCourse.klubber_id.klubber_email,
          clubName: (tempCourse.klubber_id.id === defaultClubId ? 'Dansk Dart Union' : tempCourse.klubber_id.klubber_klubnavn),
          clubTelephone: tempCourse.klubber_id.klubber_telefonnummer,
          extraContactMail: tempCourse.event_tilmelding_mail,
          extraContactTelephone: tempCourse.event_kontakt_telefon,
          documents: tempCourse.event_invitation
        }

        this.courseDetailsModal = true
        console.log(this.courseDetails)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public parseURL () : number {
    let retVal = 0

    if (this.usedroute?.params !== undefined) {
      const memberID = this.usedroute.params.userSlug.toString().split('-')[0]

      if (Number(memberID) !== undefined) {
        retVal = Number(memberID)
      }
    }

    return retVal
  }

  public danishDateString (date: string) : string {
    return CommonFunctions.toDanishDateString(date)
  }

  public toDanishDateWWeekDay (dateString: string) : string {
    return CommonFunctions.toDanishDateStringWWeekDate(dateString)
  }

  public getClockFromDateString (dateString: string) : string {
    return CommonFunctions.getHoursAndMinutes(dateString)
  }

  public priceToString (price: number) : string {
    return (price === 0 ? 'Gratis' : price.toString())
  }

  public extraInfoPresent (info: string) : boolean {
    if (info === null || info === '') {
      return false
    }

    return true
  }

  public canSignUp (registrationDeadline : string) : boolean {
    const deadline = new Date(registrationDeadline).toISOString().split('T')[0]

    if (this.todaysDate > deadline) {
      return false
    }

    return true
  }

  public beingHeld (startDate: string, endDate: string) : string {
    let retVal = ''
    const start = new Date(startDate).toISOString().split('T')[0]
    const end = new Date(endDate).toISOString().split('T')[0]

    if (start === end) {
      retVal = CommonFunctions.toDanishDateStringWWeekDate(start)
    } else {
      retVal = CommonFunctions.toDanishDateStringWWeekDate(start) + ' - ' + CommonFunctions.toDanishDateStringWWeekDate(end)
    }

    return retVal
  }

  public retrieveMemberInfo () : void {
    // Changed to use the userId from localstorage.
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()
      return
    }

    // console.log('[retrieveMemberInfo ()] usedroute = ' + JSON.stringify(this.usedroute?.params))

    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        this.memberInfo = response.data

        if (this.memberInfo.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Noget gik galt' })

          return
        }

        // A rudementary check, if the memberId from the URL, does not match the memberId retrieved via the userId, logout and go to login page
        if (this.parseURL() !== Number(this.memberInfo[0].id)) {
          AuthService.logout()
          CommonFunctions.redirectLogin()
        }

        this.memberRights = this.memberInfo[0].user_id.usrgroup

        // console.log(this.memberInfo)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveDate () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        // this.todaysDate = new Date(response.data.split('T')[0]).toISOString().split('T')[0]
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveCourses () : void {
    Events.EventsDataService.getCount(`event_status=true&event_type_id.id=5${this.courseHeld}`)
      .then((response) => {
        this.totalCourses = response.data
        this.totalNumberOfPages = Math.ceil(this.totalCourses / this.pageSizeValue)

        Events.EventsDataService.getAll(this.currentListSortingOrder, (this.filterByTimeValue === 'Kommende kurser' ? null : { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalCourses }), `event_status=true&event_type_id.id=5${this.courseHeld}`)
          .then((response) => {
            this.courses = response.data
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      await this.usedrouter.isReady()

      this.retrieveMemberInfo()
      this.retrieveDate()
      this.retrieveCourses()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
